// @ts-nocheck
/**
 * external libs
 */
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react'
import Slider from 'react-slick'
/**
 * components
 */
import MenuCard from './MenuCard'
import TopUpAccountPhis from './phis/TopUpAccountPhis'
import TopUpAccountEntity from './entity/TopUpAccountEntity'
import TopUpAccountDriver from './driver/TopUpAccountDriver'
import Portal from './../../common-components/portal/Portal'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import { useAllData } from './../../utils/all-data'
/**
 * types
 */
import { RolesType, GlobalContextType, CardType, WalletType, BalanceType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'
import useSender from '../../utils/sender'
import ts from 'typescript'

const Carts: React.FC = () => {
    const { getAllData } = useAllData()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: styles.carts__dots,
    }

    const [portalOpen, setPortalOpen] = useState<boolean>(false)
    const [portalData, setPortalData] = useState<null | any>(null)
    const { addAlert, user, cards, selectedCard, services } = useContext<GlobalContextType>(GlobalContext)
    const [walletCards, setWalletCards] = useState<(Partial<WalletType> & CardType)[] | null>(null)
    const [balance, setBalance] = useState<number | null>(null)

    const [isOpenWallets, setIsOpenWallets] = useState<boolean | null>(false)
    const [isOpenCards, setIsOpenCards] = useState<boolean | null>(false)

    const closePortal = useCallback(() => {
        setPortalData(null)
    }, [setPortalData])

    useEffect(() => {
        setPortalOpen(!!portalData)
    }, [portalData, setPortalOpen])

    const getBalance = (name) => {
        const { sizeAccount, serviceName, serviceId } = balance?.find((item) => item.serviceName === name) || {
            sizeAccount: 0,
            serviceName: '',
            serviceId: '',
        }
        const { unitLetter } = services?.find((item) => item.id === serviceId) || { unitLetter: '' }

        return { sizeAccount, serviceName, serviceId, unitLetter }
    }

    const { http } = useSender()

    const getCards = async () => {
        if (user?.Role && user?.uID) {
            try {
                const servicesRequest = await http.get(`/${user.Role}/${user.uID}/services`)
                const services = servicesRequest?.data?.data || []

                const cardsArr = cards?.data ? cards?.data.data : cards
                const walletsArray: WalletType[][] = user.Role === RolesType.drivers

                const balanceObj: BalanceType[] | null =
                    user.Role === RolesType.drivers
                        ? null
                        : await getAllData<BalanceType>(`/${user.Role}/${user.uID}/wallets`)

                console.log(balanceObj)
                console.log('selectedCard id', selectedCard?.id)
                const walletsRequest: WalletType[][] =
                    user.Role === RolesType.drivers || selectedCard === null || selectedCard?.id === undefined
                        ? [[]]
                        : user.Role === RolesType.individuals
                          ? await http.get(`/${user.Role}/${user.uID}/cards/${selectedCard?.id}/wallets`)
                          : await Promise.all(
                                (cardsArr || []).map((card: CardType) =>
                                    getAllData<WalletType>(`/${user.Role}/${user.uID}/cards/${card.id}/wallets`)
                                )
                            )
                const wallets = walletsRequest?.data?.data || []

                console.log(wallets)
                const result: (Partial<WalletType> & CardType)[] = (cardsArr || []).map(
                    (card: CardType, idx: number) => {
                        const wallet = (walletsArray[idx] || []).reduce(
                            (acc, walletData) => {
                                return {
                                    ...acc,
                                    monthlyLimit: acc.monthlyLimit + (walletData?.monthlyLimit || 0),
                                    dailyLimit: acc.dailyLimit + (walletData?.dailyLimit || 0),
                                    sizePurse: acc.sizePurse + (walletData?.sizePurse || 0),
                                }
                            },
                            { monthlyLimit: 0, dailyLimit: 0, sizePurse: 0 }
                        )

                        return {
                            ...card,
                            ...wallet,
                        }
                    }
                )

                wallets?.map((wallet) => {
                    const service = services.find((service) => service.id === wallet.serviceId)
                    wallet.unitLetter = service?.unitLetter
                })

                setWalletCards(user.Role === RolesType.individuals ? wallets : result)

                setBalance(balanceObj)
            } catch (e: any) {
                console.log(e)
                if (addAlert) {
                    addAlert({
                        text: `Ошибка запроса Carts.tsx: ${e?.code} | ${e?.status} | ${e?.config?.url}`,
                        type: 'error',
                    })
                }
            }
        }
    }

    useEffect(() => {
        getCards()
    }, [cards, selectedCard])

    if (walletCards === null || (balance === null && user?.Role !== RolesType.drivers)) {
        return null
    }

    return (
        <div className={styles.carts}>
            {!!walletCards.length && (
                <>
                    {walletCards.length === 1 ? (
                        <MenuCard wallet={walletCards[0]} setPortalData={setPortalData} />
                    ) : (
                        <Slider {...settings}>
                            {walletCards.map((wallet, index) => (
                                <MenuCard key={index} wallet={wallet} setPortalData={setPortalData} />
                            ))}
                        </Slider>
                    )}
                </>
            )}

            {(user?.Role === RolesType.companies || user?.Role === RolesType.individuals) && (
                <div className={styles.carts_list}>
                    <p
                        className={
                            styles.carts_list__title + (isOpenWallets ? ' ' + styles.carts_list__title_open : '')
                        }
                        onClick={() => setIsOpenWallets(!isOpenWallets)}>
                        Остаток на счете
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.5 8L10.5 12L14.5 8"
                                stroke="#007AFF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </p>
                    <div
                        className={styles.carts_list__list + (isOpenWallets ? ' ' + styles.carts_list__list_open : '')}>
                        <div
                            className={
                                styles.carts_list__list_wrapper +
                                (isOpenWallets ? ' ' + styles.carts_list__list_wrapper_open : '')
                            }>
                            {balance.map((item, index) => (
                                <div className={styles.carts__balance} key={index}>
                                    <p className={styles.carts__text}>{item.serviceName}:</p>
                                    <p className={`${styles.carts__text} ${styles.carts__text_right}`}>
                                        {getBalance(item.serviceName).sizeAccount}{' '}
                                        {getBalance(item.serviceName).unitLetter}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {!!walletCards.length && (
                <div className={styles.carts_list}>
                    <p
                        className={styles.carts_list__title + (isOpenCards ? ' ' + styles.carts_list__title_open : '')}
                        onClick={() => setIsOpenCards(!isOpenCards)}>
                        Остаток на карте
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.5 8L10.5 12L14.5 8"
                                stroke="#007AFF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </p>
                    <div className={styles.carts_list__list + (isOpenCards ? ' ' + styles.carts_list__list_open : '')}>
                        <div
                            className={
                                styles.carts_list__list_wrapper +
                                (isOpenCards ? ' ' + styles.carts_list__list_wrapper_open : '')
                            }>
                            {walletCards.map((item, index) => (
                                <div className={styles.carts__balance} key={index}>
                                    <p className={styles.carts__text}>{item.serviceName}:</p>
                                    <p className={`${styles.carts__text} ${styles.carts__text_right}`}>
                                        {item.sizePurse} {item.unitLetter}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <Portal title="Пополнение баланса" open={portalOpen} close={closePortal}>
                {user?.Role === RolesType.individuals && <TopUpAccountPhis close={closePortal} />}

                {user?.Role === RolesType.companies && <TopUpAccountEntity close={closePortal} />}

                {user?.Role === RolesType.drivers && <TopUpAccountDriver close={closePortal} />}
            </Portal>
        </div>
    )
}

export default Carts
