/**
 * external libs
 */
import React, { useState, useContext } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
/**
 * components
 */
import Button from '../../../common-components/button/Button'
import Field from '../../../common-components/field/Field'
import Input from '../../../common-components/input/Input'
import DropDown from '../../../common-components/drop-down/DropDown'
import FuelCard from '../../../common-components/fuel-card/FuelCard'
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * utils
 */
import { topUpAccountSchema } from './yup.account'
import useSender from './../../../utils/sender'
/**
 * styles
 */
import styles from './../../layouts.module.scss'
/**
 * types
 */
import { SelectOptionType, GlobalContextType } from './../../../types'

type TopUpAccountFormType = {
    replenishment_amount: number | ''
    terms: string | ''
}

const TopUpAccountPhis: React.FC<{ close: Function }> = ({ close }) => {
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()

    const [step, setStep] = useState<number>(1)
    const [PDFBlob, setPDFBlob] = useState<Blob | null>(null)

    const fieldTypeOptions = [
        {
            id: 1,
            label: 'Договор #1',
        },
        {
            id: 2,
            label: 'Договор #2',
        },
    ]

    // const ndsTypeOptions = [
    //     {
    //         id: 1,
    //         label: 'Ндс не облагается',
    //     },
    //     {
    //         id: 2,
    //         label: 'С ндс',
    //     },
    // ]

    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
        setValue,
    } = useForm<TopUpAccountFormType>({
        mode: 'onBlur',
        defaultValues: {
            replenishment_amount: '',
            terms: '',
        },
        resolver: yupResolver<TopUpAccountFormType>(topUpAccountSchema),
    })

    const submitHandler: SubmitHandler<TopUpAccountFormType> = async (formdata) => {
        console.log('formdata', formdata)

        // if (step === 2) {
        //     setStep(1)
        //     return
        // }

        if (step === 1) {
            try {
                await http
                    .get(`/companies/${user?.uID}/bill`, {
                        params: {
                            price: formdata.replenishment_amount,
                            contract: formdata.terms,
                        },
                        responseType: 'blob',
                    })
                    .then((response) => {
                        setPDFBlob(new Blob([response.data], { type: 'application/pdf' }))
                        setStep(2)
                    })
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }

            return true
        }
        if (step === 2) {
            close()
        }
    }

    const openPdfInNewTab = () => {
        if (PDFBlob === null) return
        const pdfUrl = URL.createObjectURL(PDFBlob)
        window.open(pdfUrl, '_blank')
    }
    const downloadPdf = () => {
        if (PDFBlob === null) return
        const link = document.createElement('a')
        link.href = URL.createObjectURL(PDFBlob)
        link.download = 'bill.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
        close()
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submitHandler)} className={styles.form}>
            <div className={styles.form__container}>
                {step === 1 && (
                    <>
                        <Controller
                            name="replenishment_amount"
                            control={control}
                            render={({ field }) => (
                                <Field label="Сумма пополнения, руб" error={errors[field.name]?.message}>
                                    <Input
                                        placeholder="Сумма пополнения"
                                        value={String(field.value) || ''}
                                        change={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const trimVal: string = (e.target.value || '').trim()
                                            //@ts-ignore
                                            if (!isNaN(trimVal)) {
                                                field.onChange(Number(trimVal))
                                            }
                                        }}
                                    />
                                </Field>
                            )}
                        />

                        {/* <Controller
                            name="nds_type"
                            control={control}
                            render={({ field }) => (
                                <Field label="НДС" error={errors[field.name]?.message}>
                                    <DropDown
                                        values={
                                            field.value
                                                ? [
                                                      fieldTypeOptions.find(
                                                          (option) => option.id === field.value
                                                      ) as SelectOptionType,
                                                  ]
                                                : []
                                        }
                                        changeHandler={(vals) => field.onChange(vals?.[0].id || null)}
                                        placeholder=""
                                        isGray={true}
                                        options={ndsTypeOptions}
                                    />
                                </Field>
                            )}
                        /> */}

                        <Controller
                            name="terms"
                            control={control}
                            render={({ field }) => (
                                <Field label="Выберите договор" error={errors[field.name]?.message}>
                                    <Input placeholder="Договор" value={field.value} change={field.onChange} />
                                </Field>
                            )}
                        />
                    </>
                )}

                {step === 2 && (
                    <div className={styles.form__buttons}>
                        <Button label="Открыть PDF" type="button" handler={openPdfInNewTab} />
                        <Button label="Скачать PDF" type="button" handler={downloadPdf} />
                    </div>
                )}

                {/* {step === 2 && (
                    <Controller
                        name="card"
                        control={control}
                        render={({ field }) => (
                            <div className={styles.form__cards}>
                                <FuelCard
                                    number="1111-0000-1111-0000"
                                    owner="Николай Константинов"
                                    clickHandler={() => {
                                        field.onChange(1)
                                        setStep(3)
                                    }}
                                />

                                <FuelCard
                                    number="1111-0000-1111-0000"
                                    owner="не Николай Константинов"
                                    clickHandler={() => {
                                        field.onChange(2)
                                        setStep(3)
                                    }}
                                />

                                <FuelCard
                                    number="1111-0000-1111-0000"
                                    owner="ну Николай Константинов"
                                    clickHandler={() => {
                                        field.onChange(3)
                                        setStep(3)
                                    }}
                                />
                            </div>
                        )}
                    />
                )} */}

                {/* {step === 3 && (
                    <>
                        <p className={styles.form__date}>27.03.2022 в 3:03</p>

                        <div className={styles.form__finishInfo}>
                            <Field label="Топливная карта №" noError={true}>
                                <div className={styles.form__cardBG}>
                                    <p className={styles.form__cardNumber}>1111-0000-1111-0000</p>
                                </div>
                            </Field>

                            <Field label="Пользователь карты" noError={true}>
                                <p className={styles.form__userFIO}>Николай Константинов ENTITY</p>
                            </Field>

                            <Field label="Сумма" noError={true}>
                                <p className={`${styles.form__userFIO} ${styles.form__userFIO_green}`}>+ 5 000 Руб.</p>
                            </Field>
                        </div>
                    </>
                )} */}
            </div>

            <Button
                label={step === 1 ? 'Выставить счет' : step === 2 ? 'Закрыть' : ''}
                type="submit"
                subClasses={styles.form__submit}
            />
        </form>
    )
}

export default TopUpAccountPhis
